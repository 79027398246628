:root[data-theme="light"] {
  --background-primary: #ffffff;
  --background-secondary: #f8f9fa;
  --text-primary: #333333;
  --text-secondary: #666666;
  --border-color: #e0e0e0;
  --accent-color: #6366f1;
  --accent-color-hover: #4f46e5;
  --nav-background: #ffffff;
  --nav-text: #333333;
  --card-background: #ffffff;
  --hover-background: #f0f0f0;
  --header-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --header-background: #ffffff;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
}

:root[data-theme="dark"] {
  --background-primary: #121212;
  --background-secondary: #1a1a1a;
  --text-primary: #e2e8f0;
  --text-secondary: #94a3b8;
  --border-color: #2d2d2d;
  --accent-color: #818cf8;
  --accent-color-hover: #6366f1;
  --nav-background: #1a1a1a;
  --nav-text: #e2e8f0;
  --card-background: #1e1e1e;
  --hover-background: #2d2d2d;
  --header-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  --header-background: #1a1a1a;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.2);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Apply theme variables to existing elements */
body {
  background-color: var(--background-primary);
  color: var(--text-primary);
  margin: 0;
  padding: 0;
}

/* Header Styles */
.app-header {
  width: 100%;
  height: 40px;
  position: fixed;
  background-color: var(--header-background) !important;
  display: flex;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--header-shadow);
  transition: all 0.3s ease;
  padding: 0;
  margin: 0;
}

.book-header-title h1 {
  font-size: 1.6rem;
  font-weight: 300;
}

.book-header-title img {
  width: 40px;
  height: 40px;
  position: relative;
  top: 10px;
}

.book-header-title {
  display: flex;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  text-decoration: none;
  color: var(--nav-text) !important;
}

.book-header-title-text {
  font-size: 20px;
  font-weight: bold;
  color: var(--nav-text) !important;
  transition: color 0.3s ease;
}

.header-back-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--nav-text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.header-back-button:hover {
  background-color: var(--hover-background);
  transform: translateX(-2px);
}

.header-back-button svg {
  color: var(--nav-text) !important;
  stroke: var(--nav-text) !important;
  transition: all 0.3s ease;
  stroke-width: 2px;
}

.book-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 10px 0;
  justify-content: center;
}

.category-tag {
  background-color: var(--accent-color);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: inline-block;
}

.book-categories-horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 5px 0;
  justify-content: center;
  max-width: 200px;
}

.file-upload-container {
  margin-bottom: 10px;
}

.selected-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 8px 12px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  font-size: 14px;
}

.clear-file {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 0 8px;
  font-size: 16px;
}

.clear-file:hover {
  color: #c82333;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--background-primary);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--background-secondary);
  border-top: 5px solid var(--accent-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}