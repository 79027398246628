/* File Upload Styles */
.file-upload-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.file-input {
  width: 100%;
  height: 48px;
  padding: 0 16px 0 48px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  font-size: 15px;
  color: #1e293b;
  background: #f8fafc;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-input:hover {
  background: white;
  border-color: #cbd5e1;
}

.selected-file {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #f1f5f9;
  border-radius: 8px;
  font-size: 14px;
  color: #475569;
}

.selected-file svg {
  color: #6366f1;
  flex-shrink: 0;
}

.selected-file span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clear-file {
  background: none;
  border: none;
  color: #94a3b8;
  cursor: pointer;
  padding: 4px;
  font-size: 18px;
  line-height: 1;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.clear-file:hover {
  color: #ef4444;
  background: #fee2e2;
}

.helper-text {
  color: #64748b;
  font-size: 13px;
  margin-top: 4px;
  line-height: 1.5;
}

/* Image Preview Styles */
.image-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  background: #f8fafc;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}
