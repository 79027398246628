.nav-bar {
  display: none;
}

.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  font-size: 14px;
  color: var(--text-secondary);
  text-decoration: none;
  transition: all 0.2s ease;
}

.nav-link:hover {
  color: var(--text-primary);
}

.nav-link.active {
  color: var(--text-primary);
  font-weight: 500;
}

.nav-link svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
  transition: all 0.2s ease;
}

.nav-link:hover svg {
  transform: scale(1.1);
}

.nav-link.active svg {
  stroke-width: 2;
}

.app-header {
  width: 100%;
  height: 40px;
  position: fixed;
  background: var(--nav-background);
  display: flex;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
  border-bottom: 1px solid var(--border-color);
}

.header-content {
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-back {
  flex-shrink: 0;
}

.book-header-title-text {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
}

.header-back-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.header-back-button:hover {
  background-color: var(--hover-background);
  transform: translateX(-2px);
}

.book-header-title {
  display: block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  text-align: center;
  color: var(--text-primary);
  margin: 0;
  text-decoration: none;
}

.header-search {
  flex: 1;
  max-width: 500px;
  position: relative;
}

.header-search .search-input-container {
  margin: 0;
  height: 32px;
  padding: 6px 12px;
}

.header-search .search-input {
  height: 100%;
  padding: 0;
}

.search-dropdown {
  max-width: 425px;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: var(--card-background);
  border-radius: 8px;
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--border-color);
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.search-dropdown.dark {
  background: var(--card-background);
  border-color: var(--border-color);
}

.search-result-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid var(--border-color);
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background: var(--hover-background);
}

.search-result-image {
  width: 40px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}

.search-result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-result-info {
  flex: 1;
  min-width: 0;
}

.search-result-info h4 {
  margin: 0 0 4px 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result-info p {
  margin: 0;
  font-size: 12px;
  color: var(--text-secondary);
}

.search-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.search-loading .loading-spinner {
  width: 24px;
  height: 24px;
  border: 2px solid var(--border-color);
  border-top-color: var(--accent-color);
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.no-results {
  padding: 16px;
  text-align: center;
  color: var(--text-secondary);
  font-size: 14px;
}

.view-all-results {
  padding: 12px;
  text-align: center;
  background: var(--background-secondary);
  color: var(--accent-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-all-results:hover {
  background: var(--hover-background);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Hide search on mobile */
@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
  
  .book-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.title-header {
  top: 0;
  height: 50px;
  position: fixed;
  display: flex;
  align-content: center;
  width: 100%;
  background: var(--background-primary);
  flex-wrap: wrap;
  justify-content: center;
}
.logo-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hamburger Menu and Side Navigation Styles */
.hamburger-button {
  position: fixed;
  right: 20px;
  top: 5px;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.hamburger-button svg {
  color: var(--nav-text);
  stroke: var(--nav-text);
  transition: all 0.3s ease;
}

.hamburger-button:hover {
  transform: scale(1.05);
  background-color: var(--hover-background);
}

.side-nav {
  position: fixed;
  top: 0;
  right: -350px;
  height: 100vh;
  background: var(--nav-background);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  width: 200px;
  padding: 70px 10px 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-left: 1px solid var(--border-color);
}

.side-nav::after {
  display: none;
}

.side-nav > *:not(.nav-section:first-child) {
  padding-right: 16px;
}

.side-nav > :last-child {
  margin-bottom: 40px;
}

.side-nav .nav-section {
  position: sticky;
  top: 0;
  background: var(--nav-background);
  padding: 12px 16px;
  margin: 0;
  z-index: 1;
}

.side-nav::-webkit-scrollbar {
  width: 6px;
}

.side-nav::-webkit-scrollbar-track {
  background: transparent;
}

.side-nav::-webkit-scrollbar-thumb {
  background-color: var(--text-secondary);
  border-radius: 3px;
}

.side-nav {
  scrollbar-width: thin;
  scrollbar-color: var(--text-secondary) transparent;
}

.side-nav.open {
  right: 0;
}

.side-nav .nav-link {
  padding: 16px 20px;
  color: var(--nav-text);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  opacity: 0.8;
}

.side-nav .nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: -1;
}

.side-nav .nav-link:hover {
  color: var(--nav-text);
  background: var(--hover-background);
  opacity: 1;
}

.side-nav .nav-link.active {
  background: var(--hover-background);
  color: var(--nav-text);
  opacity: 1;
  border-left: 4px solid var(--accent-color);
}

.side-nav .nav-link svg {
  color: var(--nav-text);
  opacity: 0.8;
}

.side-nav .nav-link:hover svg,
.side-nav .nav-link.active svg {
  opacity: 1;
}

.side-nav .nav-link span {
  font-size: 15px;
  letter-spacing: 0.3px;
  white-space: nowrap;
}

.side-nav .nav-section {
  padding: 0 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--nav-text);
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  opacity: 0.6;
}

.side-nav .nav-section:last-of-type {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.side-nav .user-info {
  background: var(--background-secondary);
  border-radius: 12px;
  margin-bottom: 8px;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
  margin: 10px;
}

.side-nav .user-info.clickable {
  cursor: pointer;
}

.side-nav .user-info.clickable:hover {
  background: var(--hover-background);
  transform: translateY(-1px);
  border-color: var(--accent-color);
}

.side-nav .user-info-main {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px;
}

.side-nav .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid var(--border-color);
  flex-shrink: 0;
}

.side-nav .user-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.side-nav .user-avatar svg {
  color: var(--text-secondary);
  opacity: 0.8;
}

.side-nav .user-details {
  flex-grow: 1;
  min-width: 0; /* Allows text truncation to work */
}

.side-nav .user-name {
  width: 100px;
  font-weight: 600;
  color: var(--nav-text);
  font-size: 15px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.side-nav .user-email {
  color: var(--text-secondary);
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  width: 100px;
}

.side-nav .token-balance {
  display: flex;
  align-items: center;
  gap: 6px;
  color: white;
  font-size: 13px;
  padding: 8px 12px;
  margin-top: 8px;
  background: var(--accent-color);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.side-nav .token-balance.light {
  background: var(--accent-color);
  color: white;
}

.side-nav .token-balance.dark {
  background: var(--accent-color);
  color: white;
}

.side-nav .token-balance svg {
  color: white;
  stroke-width: 2;
  transition: all 0.2s ease;
}

.side-nav .token-balance.light svg {
  color: white;
}

.side-nav .token-balance.dark svg {
  color: white;
}

.side-nav .token-balance span {
  font-weight: 500;
  color: white;
  transition: color 0.2s ease;
}

.side-nav .token-balance:hover {
  background: var(--accent-color-hover);
  transform: translateY(-1px);
}

.side-nav .token-balance:hover svg {
  transform: scale(1.1);
}

.side-nav .logout-button {
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  color: #ef4444;
  justify-content: flex-start;
  padding: 16px 20px;
}

.side-nav .logout-button:hover {
  background: var(--hover-background);
  color: #dc2626;
}

.side-nav .logout-button svg {
  width: 20px;
  height: 20px;
  stroke-width: 2;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 999;
  backdrop-filter: blur(4px);
}

.overlay.visible {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .side-nav {
    width: 280px;
    padding-top: 70px;
  }
  
  .side-nav > *:not(.nav-section:first-child) {
    padding-right: 12px;
  }

  .side-nav .nav-link {
    padding: 14px 16px;
  }

  .side-nav .nav-link svg {
    min-width: 24px;
    min-height: 24px;
    width: 24px !important;
    height: 24px !important;
  }

  .side-nav .nav-link span {
    font-size: 16px;
  }

  .side-nav .user-name {
    font-size: 16px;
  }

  .side-nav .user-email {
    font-size: 14px;
  }

  .side-nav .nav-section {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 4px;
  }

  .hamburger-button {
    padding: 12px;
  }

  .hamburger-button svg {
    width: 24px;
    height: 24px;
  }
}

/* Ensure icons don't shrink */
.side-nav .nav-link svg,
.side-nav .logout-button svg {
  flex-shrink: 0;
  min-width: 20px;
  min-height: 20px;
}

/* Ensure text doesn't wrap */
.side-nav .nav-link span {
  white-space: nowrap;
}

.theme-toggle {
  background: none;
  border: none;
  color: var(--nav-text);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.theme-toggle:hover {
  background-color: var(--hover-background);
}

.theme-toggle svg {
  width: 20px;
  height: 20px;
}