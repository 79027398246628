.token-cost-manager {
  padding: 32px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 70px;
}

.section-header h2 {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--text-primary);
}

.section-header h2 svg {
  color: var(--accent-color);
}

.section-header p {
  color: var(--text-secondary);
  font-size: 15px;
}

.create-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.create-button.light {
  background: var(--accent-color);
  color: white;
}

.create-button.dark {
  background: var(--accent-color);
  color: white;
}

.create-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.create-form-container {
  margin-bottom: 32px;
  padding: 24px;
  border-radius: 12px;
}

.create-form-container.light {
  background: var(--card-background);
  border: 1px solid var(--border-color);
}

.create-form-container.dark {
  background: var(--card-background);
  border: 1px solid var(--border-color);
}

.create-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background: var(--input-background);
  color: var(--text-primary);
  font-size: 14px;
}

.form-group textarea {
  min-height: 80px;
  resize: vertical;
}

.form-group select {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background: var(--input-background);
  color: var(--text-primary);
  font-size: 14px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 8px auto;
  padding-right: 32px;
}

.form-group select.dark {
  background-color: var(--background-secondary);
  color: var(--text-primary);
}

.form-group select.light {
  background-color: white;
  color: var(--text-primary);
}

.form-group select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1);
}

.form-group select:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-actions {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.submit-button,
.cancel-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.submit-button {
  background: var(--accent-color);
  color: white;
}

.cancel-button {
  background: var(--danger-color);
  color: white;
}

.submit-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}

.submit-button:disabled,
.cancel-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.costs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.cost-card {
  padding: 24px;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.cost-card.light {
  background: var(--card-background);
  border: 1px solid var(--border-color);
}

.cost-card.dark {
  background: var(--card-background);
  border: 1px solid var(--border-color);
}

.cost-card:hover {
  transform: translateY(-2px);
}

.cost-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.cost-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.cost-actions {
  display: flex;
  gap: 8px;
}

.edit-button,
.save-button,
.cancel-button,
.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-button {
  background: var(--accent-color);
  color: white;
}

.save-button {
  background: var(--success-color);
  color: white;
}

.cancel-button {
  background: var(--danger-color);
  color: white;
}

.delete-button {
  background: var(--danger-color);
  color: white;
}

.edit-button:hover,
.save-button:hover,
.cancel-button:hover,
.delete-button:hover {
  opacity: 0.9;
}

.edit-button:disabled,
.save-button:disabled,
.cancel-button:disabled,
.delete-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cost-amount {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 700;
  color: var(--accent-color);
  margin-bottom: 12px;
}

.cost-amount svg {
  color: var(--accent-color);
}

.cost-description {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 16px;
  line-height: 1.5;
}

.cost-status {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
}

.cost-status.active {
  background: #ecfdf5;
  color: #059669;
}

.cost-status.inactive {
  background: #fee2e2;
  color: #ef4444;
}

.dark .cost-status.active {
  background: rgba(5, 150, 105, 0.1);
  color: #34d399;
}

.dark .cost-status.inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #f87171;
}

.edit-form {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.edit-form .form-group {
  margin-bottom: 0;
}

.no-actions-message {
  margin-top: 8px;
  font-size: 13px;
  color: var(--danger-color);
  font-style: italic;
}

.cost-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.book-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  background: var(--background-secondary);
  color: var(--text-secondary);
}

.book-badge svg {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .token-cost-manager {
    padding: 20px;
  }

  .costs-grid {
    grid-template-columns: 1fr;
  }

  .cost-card {
    padding: 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay.dark {
  background-color: rgba(0, 0, 0, 0.7);
}

.token-cost-modal {
  background: var(--card-background);
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.token-cost-modal.dark {
  border: 1px solid var(--border-color);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid var(--border-color);
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
  color: var(--text-primary);
}

.modal-close {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.modal-close:hover {
  background: var(--background-secondary);
  color: var(--text-primary);
}

.modal-close:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-content {
  padding: 24px;
}

.modal-content .create-form {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .token-cost-modal {
    width: 95%;
    margin: 10px;
  }
  
  .modal-header {
    padding: 16px 20px;
  }
  
  .modal-content {
    padding: 20px;
  }
} 