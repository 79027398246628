.book-upload-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: var(--background-primary);
  color: var(--text-primary);
}

.header-section {
  text-align: center;
  margin-bottom: 3rem;
}

.header-section h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--text-primary) 0%, var(--accent-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
}

.header-divider {
  width: 80px;
  height: 4px;
  background: var(--accent-color);
  margin: 1.5rem auto;
  border-radius: 2px;
}

.header-subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.upload-description {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.feature-card {
  background: var(--background-secondary);
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--border-color);
}

.feature-card svg {
  color: var(--accent-color);
  margin-bottom: 1rem;
  width: 32px;
  height: 32px;
}

.feature-card h3 {
  color: var(--text-primary);
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.feature-card p {
  color: var(--text-secondary);
  font-size: 0.9rem;
  line-height: 1.5;
}

.upload-box {
  border: 2px dashed var(--border-color);
  border-radius: 16px;
  padding: 3rem 2rem;
  text-align: center;
  margin-bottom: 2rem;
  background: var(--background-secondary);
  transition: all 0.3s ease;
  cursor: pointer;
}

.upload-box:hover {
  border-color: var(--accent-color);
  background: var(--background-primary);
  transform: translateY(-2px);
}

.file-input {
  display: none;
}

.file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  color: var(--text-secondary);
}

.file-label svg {
  color: var(--accent-color);
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.upload-box:hover .file-label svg {
  transform: scale(1.1);
}

.file-label span:first-of-type {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-primary);
}

.file-types {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.error-message {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--danger-color);
  background: var(--background-secondary);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  border: 1px solid var(--danger-color);
}

.file-info {
  background: var(--background-secondary);
  padding: 2rem;
  border-radius: 12px;
  margin-top: 2rem;
  border: 1px solid var(--border-color);
}

.file-info h3 {
  margin-bottom: 1.5rem;
  color: var(--text-primary);
  font-size: 1.2rem;
  font-weight: 600;
}

.file-info p {
  margin: 0.75rem 0;
  color: var(--text-secondary);
  font-size: 1rem;
}

.cost-breakdown {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.cost-breakdown h4 {
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.upload-button {
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background: var(--accent-color);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.upload-button:hover:not(:disabled) {
  background: var(--accent-color-hover);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.upload-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Loading State */
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: var(--text-secondary);
}

/* Authentication Required State */
.auth-required {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
  background: var(--background-secondary);
  border-radius: 16px;
  padding: 3rem 2rem;
  border: 1px solid var(--border-color);
}

.auth-required svg {
  color: var(--accent-color);
  margin-bottom: 2rem;
  width: 64px;
  height: 64px;
}

.auth-required h2 {
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.auth-required p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.auth-button {
  padding: 1rem 2.5rem;
  background: var(--accent-color);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 10px;
}

.auth-button:hover {
  background: var(--accent-color-hover);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
} 