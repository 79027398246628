.books-page {
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--background-primary);
}

.books-page__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.books-page__spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--border-color);
  border-top-color: var(--accent-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.books-page__content {
  max-width: 1400px;
  margin: 0 auto;
}

.books-page__section {
  margin-bottom: 3rem;
}

/* Empty State */
.books-page__empty-state {
  text-align: center;
  padding: 4rem 2rem;
  background: var(--card-background);
  border-radius: 24px;
  border: 1px solid var(--border-color);
  max-width: 600px;
  margin: 2rem auto;
  margin-top: 100px;
}

.books-page__empty-state-icon {
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

.books-page__empty-state h2 {
  font-size: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.books-page__empty-state p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.books-page__empty-state-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.books-page__empty-state-button--primary,
.books-page__empty-state-button--secondary {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.2s ease;
  font-size: 1rem;
  text-decoration: none;
}

.books-page__empty-state-button--primary {
  background: var(--accent-color);
  color: white;
}

.books-page__empty-state-button--primary:hover {
  background: var(--accent-color-hover);
  transform: translateY(-2px);
}

.books-page__empty-state-button--secondary {
  background: var(--card-background);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.books-page__empty-state-button--secondary:hover {
  background: var(--hover-background);
  border-color: var(--accent-color);
  transform: translateY(-2px);
}

/* Characters Section */
.books-page__characters {
  margin-bottom: 3rem;
  padding: 2rem;
  background: var(--card-background);
  border-radius: 24px;
  border: 1px solid var(--border-color);
}

.books-page__section-header {
  margin-bottom: 2rem;
}

.books-page__section-header h2 {
  font-size: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.books-page__section-header p {
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.books-page__characters-by-book {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.books-page__book-characters {
  background: var(--background-primary);
  border-radius: 16px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
}

.books-page__book-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-primary);
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
  transition: color 0.2s ease;
}

.books-page__book-title:hover {
  color: var(--accent-color);
}

.books-page__book-title svg {
  color: var(--accent-color);
  transition: transform 0.2s ease;
}

.books-page__book-title:hover svg {
  transform: translateX(4px);
}

.books-page__character-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.books-page__character-compact {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.books-page__character-compact:hover {
  transform: translateY(-2px);
  border-color: var(--accent-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.books-page__character-compact-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.books-page__character-compact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.books-page__character-compact-info {
  flex: 1;
  min-width: 0;
}

.books-page__character-compact-info h4 {
  color: var(--text-primary);
  font-size: 1rem;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.books-page__character-compact-stats {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.books-page__character-compact-stats span {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.books-page__character-compact-stats svg {
  color: var(--accent-color);
}

/* Character Modal */
.books-page__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.books-page__character-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  background: var(--card-background);
  border-radius: 24px;
  z-index: 1001;
  overflow-y: auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.books-page__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.books-page__modal-close:hover {
  background: var(--hover-background);
  color: var(--text-primary);
}

.books-page__modal-header {
  padding: 2rem 2rem 0;
  text-align: center;
}

.books-page__modal-image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  overflow: hidden;
  border: 4px solid var(--accent-color);
}

.books-page__modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.books-page__modal-title h2 {
  font-size: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.books-page__modal-title p {
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.books-page__modal-content {
  padding: 2rem;
}

.books-page__modal-section {
  margin-bottom: 2rem;
}

.books-page__modal-section h3 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.books-page__modal-section p {
  color: var(--text-secondary);
  line-height: 1.6;
}

.books-page__modal-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.books-page__modal-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--background-primary);
  border-radius: 20px;
  color: var(--text-secondary);
  font-size: 0.875rem;
  border: 1px solid var(--border-color);
}

.books-page__modal-tag svg {
  color: var(--accent-color);
}

.books-page__modal-stats {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.books-page__modal-stats span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.books-page__modal-stats svg {
  color: var(--accent-color);
}

.books-page__modal-chat-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
  background: var(--accent-color);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.books-page__modal-chat-button:hover {
  background: var(--accent-color-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(var(--accent-color-rgb), 0.3);
}

/* Hero Section */
.books-page__hero {
  position: relative;
  min-height: 400px;
  margin-bottom: 3rem;
  border-radius: 24px;
  overflow: hidden;
  background: var(--card-background);
}

.books-page__hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.books-page__hero-background-book,
.books-page__hero-background-character {
  position: absolute;
  width: 300px;
  height: 450px;
  background-size: cover;
  background-position: center;
  opacity: 0.15;
  filter: blur(2px);
  animation: float 6s ease-in-out infinite;
  transform-origin: center;
}

.books-page__hero-background-book:nth-child(1) { top: -10%; left: 5%; }
.books-page__hero-background-book:nth-child(2) { top: 20%; left: 25%; }
.books-page__hero-background-book:nth-child(3) { top: -5%; left: 45%; }
.books-page__hero-background-book:nth-child(4) { top: 15%; left: 65%; }
.books-page__hero-background-book:nth-child(5) { top: -15%; left: 85%; }

.books-page__hero-background-character {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.books-page__hero-background-character:nth-child(6) { bottom: -10%; left: 10%; }
.books-page__hero-background-character:nth-child(7) { bottom: 20%; left: 40%; }
.books-page__hero-background-character:nth-child(8) { bottom: -5%; left: 70%; }

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(2deg);
  }
}

.books-page__hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    var(--card-background) 0%,
    rgba(var(--accent-color-rgb), 0.1) 100%
  );
  backdrop-filter: blur(10px);
}

.books-page__hero-content {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.books-page__hero-content h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 700;
}

.books-page__hero-content p {
  font-size: clamp(1.1rem, 2vw, 1.25rem);
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.books-page__hero-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.books-page__hero-stat {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--background-primary);
  border-radius: 16px;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
}

.books-page__hero-stat:hover {
  transform: translateY(-4px);
  border-color: var(--accent-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.books-page__hero-stat svg {
  color: var(--accent-color);
  flex-shrink: 0;
}

.books-page__hero-stat-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.books-page__hero-stat-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.books-page__hero-stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
} 