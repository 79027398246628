/* Featured Hero Styles */
.featured-hero {
  position: relative;
  width: 100%;
  min-height: 700px;
  height: 90vh;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.featured-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: 2;
  background: linear-gradient(to bottom, 
    var(--nav-bg-color, rgba(26, 26, 26, 0.9)) 0%,
    var(--nav-bg-color-transparent, rgba(26, 26, 26, 0)) 100%);
  backdrop-filter: blur(10px);
  pointer-events: none;
}

.featured-hero.dark {
  background: #1a1a1a;
  color: #fff;
}

.featured-hero.light {
  background: #f8fafc;
  color: #1e293b;
}

.featured-hero.transitioning {
  opacity: 0.5;
}

.featured-hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.featured-hero-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.15;
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.featured-hero-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex: 1;
  padding: 60px;
  gap: 60px;
  align-items: flex-start;
  transition: opacity 0.3s ease-in-out;
}

.featured-hero-book {
  position: sticky;
  top: 60px;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}

.featured-hero-image {
  width: auto;
  height: auto;
  max-height: 70vh;
  min-width: 400px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.featured-hero-image:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 30px 60px -15px rgba(0, 0, 0, 0.7);
}

.featured-hero-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
}

.featured-hero-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 8px;
  background: linear-gradient(135deg, #fff 0%, #e0e7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-hero.light .featured-hero-header h1 {
  background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-hero-header h2 {
  font-size: 1.8rem;
  font-weight: 400;
  opacity: 0.8;
  margin-bottom: 16px;
}

.featured-hero-tagline {
  font-size: 1.4rem;
  font-style: italic;
  opacity: 0.9;
  line-height: 1.6;
}

.featured-hero-metadata {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.hero-metadata-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.featured-hero.light .hero-metadata-item {
  background: rgba(0, 0, 0, 0.05);
}

.metadata-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  opacity: 0.7;
}

.metadata-value {
  font-size: 1.1rem;
  font-weight: 600;
}

.featured-hero-description {
  font-size: 1.2rem;
  line-height: 1.7;
  opacity: 0.9;
  max-width: 600px;
}

.featured-hero-categories {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.hero-category-tag {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.95rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.featured-hero.light .hero-category-tag {
  background: rgba(0, 0, 0, 0.05);
}

.hero-category-tag:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.featured-hero.light .hero-category-tag:hover {
  background: rgba(0, 0, 0, 0.1);
}

.featured-hero-actions {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.hero-primary-action,
.hero-secondary-action {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 32px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  border: none;
}

.hero-primary-action {
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  color: white;
}

.hero-primary-action:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px -5px rgba(99, 102, 241, 0.5);
}

.hero-secondary-action {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: white;
}

.featured-hero.light .hero-secondary-action {
  background: rgba(0, 0, 0, 0.05);
  color: #1e293b;
}

.hero-secondary-action:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.featured-hero.light .hero-secondary-action:hover {
  background: rgba(0, 0, 0, 0.1);
}

.featured-hero-navigation {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 20px;
  background: linear-gradient(to top, 
    var(--nav-bg-color, rgba(26, 26, 26, 0.9)) 0%,
    var(--nav-bg-color-transparent, rgba(26, 26, 26, 0)) 100%);
  backdrop-filter: blur(10px);
}

.featured-hero.dark {
  --nav-bg-color: rgba(26, 26, 26, 0.9);
  --nav-bg-color-transparent: rgba(26, 26, 26, 0);
}

.featured-hero.light {
  --nav-bg-color: rgba(248, 250, 252, 0.9);
  --nav-bg-color-transparent: rgba(248, 250, 252, 0);
}

.hero-nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.featured-hero.light .hero-nav-button {
  background: rgba(0, 0, 0, 0.05);
  color: #1e293b;
}

.hero-nav-button:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.featured-hero.light .hero-nav-button:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.1);
}

.hero-nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.featured-hero-indicators {
  display: flex;
  gap: 8px;
}

.hero-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;
}

.featured-hero.light .hero-indicator {
  background: rgba(0, 0, 0, 0.2);
}

.hero-indicator.active {
  background: white;
  transform: scale(1.2);
}

.featured-hero.light .hero-indicator.active {
  background: #1e293b;
}

.hero-indicator:hover:not(.active) {
  background: rgba(255, 255, 255, 0.5);
}

.featured-hero.light .hero-indicator:hover:not(.active) {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .featured-hero {
    min-height: 600px;
  }

  .featured-hero-content {
    padding: 40px;
    gap: 40px;
  }

  .featured-hero-book {
    top: 40px;
  }

  .featured-hero-image {
    max-width: 300px;
  }

  .featured-hero-header h1 {
    font-size: 2.8rem;
  }

  .featured-hero-header h2 {
    font-size: 1.6rem;
  }

  .featured-hero-description {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .featured-hero {
    height: auto;
    min-height: 100vh;
  }

  .featured-hero-content {
    flex-direction: column;
    padding: 30px;
    gap: 30px;
    text-align: center;
  }

  .featured-hero-book {
    position: relative;
    top: 0;
    width: 100%;
    justify-content: center;
  }

  .featured-hero-image {
    width: 70%;
    height: auto;
    min-width: 100px;
  }

  .featured-hero-details {
    align-items: center;
  }

  .featured-hero-header h1 {
    font-size: 2.4rem;
  }

  .featured-hero-header h2 {
    font-size: 1.4rem;
  }

  .featured-hero-metadata {
    justify-content: center;
  }

  .featured-hero-categories {
    justify-content: center;
  }

  .featured-hero-actions {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }

  .featured-hero-navigation {
    position: relative;
    margin-top: auto;
  }
}