.search-books-container {
  margin: 0 auto;
}

.search-header {
  position: sticky;
  top: 50px;
  background: var(--background-primary);
  padding: 20px 0;
  margin-bottom: 20px;
  z-index: 100;
}

.search-input-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 12px 16px;
  gap: 12px;
  max-width: 400px;
  margin: 0 auto;
  transition: all 0.2s ease;
}

.search-input-container.dark {
  background: var(--card-background);
}

.search-input-container.light {
  background: var(--background-secondary);
  border: 1px solid var(--border-color);
}

.search-input-container svg {
  color: var(--text-secondary);
}

.search-input {
  border: none;
  background: none;
  width: 100%;
  font-size: 16px;
  padding: 3px;
  color: var(--text-primary);
}

.search-input::placeholder {
  color: var(--text-secondary);
}

.search-input:focus {
  outline: none;
}

.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  min-height: 200px;
  gap: 20px;
  padding: 20px;
}

.book-card {
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: 12px;
}

.book-card.dark {
  background: var(--card-background);
  box-shadow: var(--shadow-sm);
}

.book-card.light {
  background: var(--background-secondary);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
}

.book-card:hover {
  transform: translateY(-4px);
}

.book-card.dark:hover {
  box-shadow: var(--shadow-lg);
}

.book-card.light:hover {
  box-shadow: var(--shadow-md);
  border-color: var(--accent-color);
}

.book-card a {
  text-decoration: none;
  color: inherit;
}

.book-image {
  aspect-ratio: 2/3;
  overflow: hidden;
  background: var(--background-secondary);
}

.book-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-info {
  padding: 12px;
}

.book-info h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.book-info.dark h3 {
  color: var(--text-primary);
}

.book-info.light h3 {
  color: var(--text-primary);
}

.book-info p {
  margin: 0;
  font-size: 14px;
  color: var(--text-secondary);
}

.error-message {
  padding: 12px 16px;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 600px;
  text-align: center;
}

.error-message.dark {
  background: #ff4444;
  color: white;
}

.error-message.light {
  background: #fee2e2;
  color: #dc2626;
  border: 1px solid #fecaca;
}

.no-results {
  grid-column: 1 / -1;
  text-align: center;
  padding: 40px;
  font-size: 16px;
  border-radius: 8px;
  margin: 20px 0;
  background: var(--background-secondary);
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  border: 3px solid var(--border-color);
  border-radius: 50%;
  border-top-color: var(--accent-color);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} 

/* Responsive adjustments for search */
@media (max-width: 768px) {
  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}