.book-content {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Georgia', serif;
  font-size: 18px;
  line-height: 1.6;
  color: var(--text-primary);
  text-align: left;
  padding: 20px 40px;
  border-radius: 8px;
  z-index: 1;
  transition: background-color 0.3s, color 0.3s;
  user-select: none; /* Prevent text selection during drag */
  min-height: 70vh;
}

.book-content.dark-mode {
  color: #e0e0e0;
}

.book-content p {
  margin: 0 0 1.5em 0;
  text-indent: 1em;
  text-align: justify;
}

.book-content p:first-of-type {
  text-indent: 0;
}

.book-page-container {
  background-color: var(--background-secondary);
  padding: 100px 20px 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.3s;
}

.book-page-container.dark-mode {
  background-color: #121212;
}

.book-navigation {
  display: none;
}

.page-number {
  font-family: 'Georgia', serif;
  color: var(--text-secondary);
  font-style: italic;
  transition: color 0.3s;
  background-color: var(--card-background);
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: var(--shadow-sm);
  margin-bottom: 10px;
}

.page-number.dark-mode {
  color: #888;
  background-color: rgba(26, 26, 26, 0.95);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.reader-controls {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.font-size-controls {
  display: flex;
  gap: 5px;
}

.font-size-btn {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  padding: 8px 12px;
  border-radius: 20px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, opacity 0.3s, color 0.3s;
  box-shadow: var(--shadow-sm);
}

.font-size-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.font-size-btn.dark-mode {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.font-size-btn.dark-mode:disabled {
  opacity: 0.5;
  background-color: #333;
}

.dark-mode-toggle {
  position: fixed;
  bottom: 120px;
  right: 20px;
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s;
  z-index: 1000;
  box-shadow: var(--shadow-sm);
}

.dark-mode-toggle.dark-mode {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--nav-background);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-sm);
  z-index: 998;
  transition: background-color 0.3s, color 0.3s;
}

.app-header.dark-mode {
  background-color: #1a1a1a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header-back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
  color: var(--text-primary);
}

.header-back-button:hover {
  background-color: var(--hover-background);
}

.header-back-button.dark-mode:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.book-header-title {
  text-decoration: none;
  color: var(--text-primary);
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.3s;
}

.book-header-title.dark-mode {
  color: white;
}

.book-header-title:hover {
  opacity: 0.8;
}

/* Style the navigation buttons */
.submit-btn {
  padding: 8px 24px;
  border-radius: 20px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-sm);
}

.submit-btn:hover {
  background-color: var(--hover-background);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.submit-btn.dark-mode,
.dark-mode .submit-btn {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.submit-btn.dark-mode:hover,
.dark-mode .submit-btn:hover {
  background-color: #444;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container.dark-mode {
  background-color: #121212;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--background-secondary);
  border-top: 5px solid var(--accent-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transition: border-color 0.3s;
}

.spinner.dark-mode {
  border: 5px solid #333;
  border-top: 5px solid #3498db;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Character List Modal Styles */
.character-list-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--card-background);
  padding: 24px;
  border-radius: 16px;
  box-shadow: var(--shadow-lg);
  z-index: 1002;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  transition: all 0.3s ease;
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.character-list-modal.dark-mode {
  background: #1a1a1a;
  color: #e0e0e0;
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
}

.character-list-modal h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

.character-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.character-card {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: var(--background-primary);
  max-width: 400px;
}

.character-card:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.character-card.dark-mode {
  background: #2c2c2c;
}

.character-card.dark-mode:hover {
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
}

.character-card.present-current {
  border-color: #22c55e;
  background: rgba(34, 197, 94, 0.05);
}

.character-card.present-previous {
  border-color: #6366f1;
  background: rgba(99, 102, 241, 0.05);
}

.character-card-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  padding: 8px;
  justify-content: flex-end;
}

.character-card-info h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary);
}

.character-list-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
}

.character-list-close:hover {
  background-color: var(--hover-background);
}

.character-list-close.dark-mode:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.characters-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: var(--shadow-sm);
}

.characters-button.dark-mode {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.characters-button:hover {
  background-color: var(--hover-background);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.characters-button.dark-mode:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.character-presence {
  font-size: 12px;
  margin-top: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
  color: var(--text-secondary);
}

.present-current .character-presence {
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.present-previous .character-presence {
  background: rgba(99, 102, 241, 0.1);
  color: #4f46e5;
}

.character-context-hint {
  font-size: 11px;
  margin-top: 4px;
  color: #475569;
  display: flex;
  align-items: center;
  gap: 4px;
}

.character-context-hint.inactive {
  color: #94a3b8;
  font-style: italic;
}

.present-current .character-context-hint {
  color: #15803d;
}

.present-previous .character-context-hint {
  color: #4338ca;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(250, 204, 21, 0.2);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(250, 204, 21, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(250, 204, 21, 0);
  }
}

.characters-button.has-characters {
  animation: pulse 2.5s infinite;
}

/* Navigation buttons styles */
.navigation-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  padding: 0 20px;
}

.navigation-buttons-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 24px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-sm);
}

.nav-btn:hover {
  transform: translateY(-2px);
  background-color: var(--hover-background);
  box-shadow: var(--shadow-md);
}

.nav-btn.dark-mode {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.nav-btn.dark-mode:hover {
  background-color: #444;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.ai-mode-btn {
  padding: 8px 12px;
  border-radius: 20px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-mode-btn.active {
  background-color: var(--accent-color);
  color: white;
}

.ai-mode-btn:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.ai-mode-btn.dark-mode {
  background-color: #333;
  color: #fff;
}

.ai-mode-btn.dark-mode.active {
  background-color: var(--accent-color);
}

.paragraph-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.paragraph-container p {
  flex: 1;
  z-index: -1;
}

.paragraph-controls {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.paragraph-controls.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.paragraph-controls.disabled .generate-btn {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  color: var(--text-secondary);
}

.paragraph-controls.disabled .generate-btn:hover {
  transform: none;
  box-shadow: var(--shadow-sm);
}

.paragraph-container:hover .paragraph-controls {
  opacity: 1;
}

.generate-btn,
.image-btn,
.regenerate-btn {
  padding: 6px;
  border-radius: 50%;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-sm);
  position: relative;
}

.generate-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.generate-btn.disabled:hover {
  transform: none;
  box-shadow: var(--shadow-sm);
}

.image-btn {
  background-color: var(--accent-color);
  color: white;
  position: relative;
  z-index: 1001;
}

.image-btn::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1999;
  visibility: hidden;
}

.image-btn::after {
  content: '';
  position: fixed;
  top: 50%;
  right: -200px;
  width: min(80vh, 500px);
  height: min(80vh, 500px);
  transform: translate(-50%, -50%);
  background-image: var(--thumbnail-url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  box-shadow: var(--shadow-lg);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1000000;
  visibility: hidden;
}

/* Desktop hover behavior */
@media (hover: hover) {
  .image-btn:hover::before,
  .image-btn:hover::after {
    opacity: 1;
    visibility: visible;
  }
}

/* Mobile/touch behavior */
@media (hover: none) {
  .image-btn::after {
    width: 90vw;
    height: 90vw;
    max-width: 90vh;
    max-height: 90vh;
    right: 50%;
    transform: translate(0%, -50%);
  }
  
  .image-btn.active::before,
  .image-btn.active::after {
    opacity: 1;
    visibility: visible;
  }
}

.generate-btn:hover,
.image-btn:hover,
.regenerate-btn:hover {
  box-shadow: var(--shadow-md);
}

.generating-indicator {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  animation: spin 1s linear infinite;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(5px);
}

.image-modal {
  max-width: 66%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-modal img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: var(--shadow-md);
}

.image-caption {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
  padding: 15px;
  border-radius: 8px;
  line-height: 1.5;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  background-color: var(--background-secondary);
}

.modal-close:hover {
  background-color: var(--hover-background);
  transform: scale(1.1);
}

.regenerate-btn {
  border-radius: 20px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
  font-weight: 500;
  background-color: var(--accent-color);
  color: white;
}

.regenerate-btn:hover {
  transform: translateY(-2px);
}

/* Gallery view styles */
.gallery-toggle {
  position: fixed;
  bottom: 20px;
  right: 170px;
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: var(--shadow-sm);
}

.gallery-toggle:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.gallery-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--card-background);
  padding: 30px;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 2000;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.gallery-item {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
}

.gallery-item:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-md);
}

.gallery-item img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.gallery-item-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.gallery-item:hover .gallery-item-info {
  opacity: 1;
}

.menu-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--card-background);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.menu-toggle.dark-mode {
  background-color: #333;
}

.menu-toggle:hover {
  transform: scale(1.1);
}

.menu-toggle.active {
  background-color: var(--accent-color);
}

/* Character images around menu toggle */
.character-bubble {
  position: fixed;
  bottom: 20px;
  right: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1090;
}

.character-bubble-image {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--card-background);
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1090;
}

.character-bubble-image:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(34, 197, 94, 0.5);
  border-color: var(--accent-color);
}

.character-bubble-image::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 12px;
  background-color: var(--card-background);
  color: var(--text-primary);
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  margin-bottom: 8px;
  box-shadow: var(--shadow-md);
  z-index: 1100;
}

.character-bubble-image::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid transparent;
  border-top-color: var(--card-background);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  margin-bottom: 2px;
  z-index: 1100;
}

.character-bubble-image:hover::after,
.character-bubble-image:hover::before {
  opacity: 1;
  visibility: visible;
}

.character-bubble-image.dark-mode {
  border-color: #333;
}

.character-bubble-image.dark-mode::after {
  background-color: #1a1a1a;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.character-bubble-image.dark-mode::before {
  border-top-color: #1a1a1a;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Add character presence glow effect */
.menu-toggle.has-characters::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(34, 197, 94, 0.2) 0%, rgba(34, 197, 94, 0) 70%);
  animation: characterGlow 2s ease-in-out infinite;
  z-index: -1;
}

.menu-toggle.dark-mode.has-characters::before {
  background: radial-gradient(circle, rgba(34, 197, 94, 0.3) 0%, rgba(34, 197, 94, 0) 70%);
}

@keyframes characterGlow {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.hamburger-icon {
  width: 20px;
  height: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--text-primary);
  transition: all 0.3s ease;
}

.menu-toggle.active .hamburger-icon span {
  background-color: white;
}

.menu-toggle.active .hamburger-icon span:first-child {
  transform: translateY(6px) rotate(45deg);
}

.menu-toggle.active .hamburger-icon span:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active .hamburger-icon span:last-child {
  transform: translateY(-6px) rotate(-45deg);
}

.reader-menu {
  position: fixed;
  bottom: 70px;
  right: -305px;
  width: 250px;
  max-height: 80vh;
  background-color: var(--card-background);
  box-shadow: var(--shadow-lg);
  transition: right 0.3s ease;
  z-index: 1050;
  padding: 16px;
  overflow-y: auto;
  border-radius: 12px;
  margin-right: 20px;
}

.reader-menu.dark-mode {
  background-color: #1a1a1a;
}

.reader-menu.open {
  right: 0;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1040;
}

.menu-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.menu-header h3 {
  margin: 0;
  color: var(--text-primary);
  font-size: 1.1rem;
}

.menu-close {
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.menu-close:hover {
  background-color: var(--hover-background);
}

.menu-section {
  margin-bottom: 16px;
}

.menu-section h4 {
  margin: 0 0 8px 0;
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
}

.menu-btn {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: var(--background-secondary);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  transition: all 0.2s ease;
  margin-bottom: 6px;
  justify-content: center;
}

.menu-btn:hover {
  background-color: var(--hover-background);
  transform: translateY(-1px);
}

.menu-btn.active {
  background-color: var(--accent-color);
  color: white;
}

.menu-btn.has-characters {
  position: relative;
  overflow: hidden;
}

.menu-btn.has-characters::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: var(--accent-color);
  border-radius: 50%;
  margin: 8px;
}

.menu-btn.theme-btn {
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
  background-color: var(--background-primary);
  border: 1px solid var(--border-color);
}

.menu-btn.theme-btn.active {
  background-color: var(--background-secondary);
}

/* Audio Control Styles */
.menu-btn.active svg {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.menu-btn svg {
  transition: transform 0.2s ease;
}

.menu-btn:hover svg {
  transform: scale(1.1);
}

.menu-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.menu-btn:disabled:hover {
  transform: none;
  background-color: var(--background-secondary);
}

.menu-btn .spin {
  animation: spin 1s linear infinite;
}

/* Remove the old fixed positioned controls */
.reader-controls,
.characters-button {
  display: none;
}

.view-images-btn {
  padding: 8px;
  border-radius: 50%;
  border: none;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-sm);
  opacity: 0.6;
}

.view-images-btn:hover {
  opacity: 1;
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
  background-color: var(--hover-background);
}

.view-images-btn.dark-mode {
  background-color: #2a2a2a;
  color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.view-images-btn.dark-mode:hover {
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.paragraph-container:hover .view-images-btn {
  opacity: 1;
}

.generate-toggle-btn {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--background-secondary);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.95em;
  font-weight: 500;
  transition: all 0.2s ease;
  margin-top: 8px;
}

.generate-toggle-btn:hover {
  background-color: var(--hover-background);
  transform: translateY(-1px);
}

.generate-toggle-btn.active {
  background-color: var(--accent-color);
  color: white;
  border-color: transparent;
}

.generate-toggle-btn.dark-mode {
  background-color: #2a2a2a;
  border-color: rgba(255, 255, 255, 0.1);
}

.generate-toggle-btn.dark-mode:hover {
  background-color: #333;
}

.generate-toggle-btn.dark-mode.active {
  background-color: var(--accent-color);
}

.generate-section {
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-color);
}

.generate-section.dark-mode {
  background-color: #2a2a2a;
  border-color: rgba(255, 255, 255, 0.1);
}

.style-selector {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-style-input {
  margin-top: 0;
}

.custom-style-input label,
.preset-styles label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.custom-style-input input {
  width: 96%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--card-background);
  color: var(--text-primary);
  font-size: 0.95em;
  transition: all 0.2s ease;
}

.custom-style-input input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.custom-style-input input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

.preset-styles select {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-styles select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.custom-style-input.dark-mode label,
.preset-styles.dark-mode label {
  color: #bbb;
}

.custom-style-input.dark-mode input,
.preset-styles.dark-mode select {
  background-color: #2a2a2a;
  border-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
}

.custom-style-input.dark-mode input:focus,
.preset-styles.dark-mode select:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.custom-style-input.dark-mode input::placeholder {
  color: #888;
}

.generate-btn {
  margin-top: 20px;
}

.generation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 8px;
}

.generation-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: white;
}

.generation-spinner .spin {
  animation: spin 1s linear infinite;
}

.generation-spinner span {
  font-size: 16px;
  font-weight: 500;
}

.paragraph-modal-content {
  position: relative;
}


/* Mobile optimization for book content */
@media screen and (max-width: 768px) {
  .book-content {
    font-size: 14px;
    padding: 15px 20px;
    line-height: 1.5;
  }
  .image-modal {
    width: 100vw;
  }
}